import axios from "axios";

export default async function createPaystackAccount(data, setLoading){
try{
    setLoading(true)
    const response = await axios.post(process.env.REACT_APP_BASE_URL+"paystack/createdvn", data)
   if (response.data.data.status) {
    return setTimeout(() => {
       window.location.href = "/account";
     }, 5000); // 5 seconds delay
   } else {
    setLoading(false)
    return alert("Account creation failed. Contact admin");
   }
}catch(e){
    setLoading(false)
    return console.log(e)
}
}