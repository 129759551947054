import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import {
  Container,
  CssBaseline,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Link,
  Paper,
  Button,
} from "@mui/material";
import { ProductContext } from "../../../context/products";

const NecoTokens = () => {
  const products = useContext(ProductContext);
  const product = products.find((p) => p.product === "necotokens");
    console.log(product);

const allKeywords = [
  ...(product.keywords.informational || []),
  ...(product.keywords.commercial || []),
  ...(product.keywords.scalable || []),
].join(", ");

  return (
    <Container component="main" maxWidth="md" sx={{ marginBottom: 4 }}>
      <CssBaseline />
      <Helmet>
        <title>How to Purchase and Use Your NECO Token</title>
        <meta
          name="description"
          content="Learn the step-by-step process to purchase your NECO token and check your NECO results online."
        />
        <meta name="keywords" content={allKeywords} />
      </Helmet>
      <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
        <Box textAlign="center" sx={{ marginBottom: 2 }}>
          <Button
            variant="contained"
            color="primary"
            href="/product/necotokens"
          >
            BUY NECO TOKEN
          </Button>
        </Box>
        <Box className="header" sx={{ marginBottom: 2 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            How to Purchase and Use Your NECO Token: A Comprehensive Guide
          </Typography>
          <Typography variant="body1">
            Learn the step-by-step process to purchase your NECO token and check
            your NECO results online. This guide covers everything from
            registration to result checking.
          </Typography>
        </Box>
        <Box className="toc" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Table of Contents
          </Typography>
          <List>
            <ListItem component="a" href="#what-is-neco-token">
              <ListItemText primary="What is a NECO Token?" />
            </ListItem>
            <ListItem component="a" href="#purchase-token">
              <ListItemText primary="Steps to Purchase NECO Token" />
            </ListItem>
            <ListItem component="a" href="#check-result">
              <ListItemText primary="How to Check NECO Results" />
            </ListItem>
            <ListItem component="a" href="#faqs">
              <ListItemText primary="FAQs about NECO Token" />
            </ListItem>
            <ListItem component="a" href="#conclusion">
              <ListItemText primary="Conclusion" />
            </ListItem>
          </List>
        </Box>
        <Box id="what-is-neco-token" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            What is a NECO Token?
          </Typography>
          <Typography variant="body1">
            A NECO token is a unique code required to check NECO examination
            results online. This token is used to access results for SSCE, BECE,
            and NCEE exams.
          </Typography>
        </Box>
        <Box id="purchase-token" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Steps to Purchase NECO Token
          </Typography>
          <Typography variant="body1">
            <ol>
              <li>
                <strong>Register or Login:</strong> Create an account or log in
                to your existing account on the{" "}
                <Link href="https://result.neco.gov.ng/login">
                  NECO results portal
                </Link>
                .
              </li>
              <li>
                <strong>Purchase Token:</strong> Select the number of tokens,
                complete the payment via the REMITA platform, and obtain your
                token.
              </li>
              <li>
                <strong>Copy the Token:</strong> After payment, you will be
                redirected to your dashboard where you can view and copy your
                purchased token.
              </li>
            </ol>
          </Typography>
        </Box>
        <Box id="check-result" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            How to Check NECO Results
          </Typography>
          <Typography variant="body1">
            <ol>
              <li>
                Go to the{" "}
                <Link href="https://result.neco.gov.ng/">
                  NECO results portal
                </Link>
                .
              </li>
              <li>
                Enter your exam year, type, token number, and registration
                number.
              </li>
              <li>Click on "Check Result" to view your results.</li>
            </ol>
          </Typography>
        </Box>
        <Box id="faqs" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            FAQs about NECO Token
          </Typography>
          <Typography variant="body1">
            <ul>
              <li>
                How much does the NECO token cost? - The token costs N625 plus a
                service charge of N50.
              </li>
              <li>
                Can the token be used multiple times? - No, each token can only
                be used once per result.
              </li>
              <li>
                Where can I purchase the token? - The safest place to purchase
                the token is from the NECO results portal.
              </li>
            </ul>
          </Typography>
        </Box>
        <Box id="conclusion" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Conclusion
          </Typography>
          <Typography variant="body1">
            Purchasing and using a NECO token is a straightforward process that
            allows you to access your examination results quickly and securely.
            Ensure you follow the steps carefully and double-check your
            information to avoid errors.
          </Typography>
        </Box>
        <Box textAlign="center" sx={{ marginTop: 4 }}>
          <Button
            variant="contained"
            color="primary"
            href="/product/necocheckertoken"
          >
            Buy Now
          </Button>
        </Box>
      </Paper>
      <footer>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ marginTop: 2 }}
        >
          © 2023 NECO Token Purchase Guide
        </Typography>
      </footer>
    </Container>
  );
};

export default NecoTokens;
