import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ProductDescription from "./components/ProductDescription/ProductDescription";
import { useParams } from "react-router-dom";
import { ProductContext } from "../../context/products";
import { CircularProgress } from "@mui/material";
import clientProducts from "../../utils/client-product";

const Product = () => {
  const { exam } = useParams();
  const products = useContext(ProductContext);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const foundProduct = clientProducts.find((product) => product.product === exam);
      setProduct(foundProduct);
    };
    fetchData();
  }, [products, exam]);

  useEffect(() => {
    if (product) {
      document.title = product.title;
    }
  }, [product]);

  if (!product) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 50,
        }}
      >
        <CircularProgress />
        <span>Loading...</span>
      </div>
    );
  }

  const allKeywords = [
    ...(product.keywords.informational || []),
    ...(product.keywords.commercial || []),
    ...(product.keywords.scalable || []),
  ].join(", ");

  return (
    <div
      style={{
        flex: 1,
        display: "flex",
        flexDirection: "row",
        marginTop: 20,
        marginBottom: 50,
        alignItems: "center",
      }}
    >
      <Helmet>
        <title>{product.title}</title>
        <meta name="description" content={product.description} />
        <meta name="keywords" content={allKeywords} />
      </Helmet>
      <ProductDescription product={product} />
    </div>
  );
};

export default Product;
