import React from 'react';
import TestimonialCard from './TestimonialCard';
import './Testimonials.css';

const testimonials = [
  {
    review: "ResultCheckers made it incredibly easy to get my WAEC exam pins. The process was fast and straightforward.",
    name: "Ubong Alexander",
    title: "Business Owner",
    image: "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-1.png"
  },
  {
    review: "I love how ResultCheckers simplifies the process of purchasing NECO tokens. Highly recommended!",
    name: "Jacob Udoh",
    title: "Parent",
    image: "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-male-2.png"
  },
  {
    review: "Using ResultCheckers was a game changer for me. Getting my exam pins was quick and hassle-free.",
    name: "Tommy Agbaje",
    title: "Student",
    image: "https://cdn.rareblocks.xyz/collection/clarity/images/testimonial/4/avatar-female.png"
  }
];

const Testimonials = () => {
  return (
    <section className="testimonials-section">
      <div className="container">
        <div className="text-center">
          <h2 className="mt-4 text-3xl font-bold text-gray-900 sm:text-4xl xl:text-5xl">Our happy clients say about us</h2>
        </div>
        <div className="relative mt-10 md:mt-24">
          <div className="absolute -inset-x-1 inset-y-16 md:-inset-x-2 md:-inset-y-6">
            <div className="w-full h-full max-w-5xl mx-auto rounded-3xl opacity-30 blur-lg filter" style={{ background: 'linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)' }}></div>
          </div>
          <div className="relative grid max-w-lg grid-cols-1 gap-6 mx-auto md:max-w-none lg:gap-10 md:grid-cols-3">
            {testimonials.map((testimonial, index) => (
              <TestimonialCard
                key={index}
                review={testimonial.review}
                name={testimonial.name}
                title={testimonial.title}
                image={testimonial.image}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
