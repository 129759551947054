import React, { useState } from "react";
import axios from "axios";
import './sendMail.css'

const BroadcastEmail = () => {
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [message, setMessage] = useState("");

  const handleSendEmails = async () => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL + "/api/send-bulk-email",
        {
          subject,
          content,
        }
      );
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className="broadcast-email-container">
      <h2>Send Bulk Email</h2>
      <div>
        <label>Subject:</label>
        <input
          type="text"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        />
      </div>
      <div>
        <label>Content:</label>
        <textarea
          value={content}
          onChange={(e) => setContent(e.target.value)}
        ></textarea>
      </div>
      <button onClick={handleSendEmails}>Send Emails</button>
      {message && <p>{message}</p>}
    </div>
  );
};

export default BroadcastEmail;
