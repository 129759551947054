// src/FileUpload.js

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Container, Typography, Box, TextField } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const Uploader = () => {
  const { exam } = useParams();
  const [file, setFile] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (
      exam !== "waecresultcheckers" &&
      exam !== "necotokens" &&
      exam !== "nabtebresultcheckers" &&
      exam !== "waecgce" &&
      exam !== "nabtebgce"
    ) {
      window.location.href = "/";
    }
  }, [exam]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}upload/${exam}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      alert("File uploaded successfully!");
      window.location.href = "/";
    } catch (error) {
      console.error("Error uploading file:", error?.response?.data);
      alert(error?.response?.data);
    }
  };

  return (
    <Container
      maxWidth="sm"
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
          padding: "20px",
          border: "1px solid #ccc",
          borderRadius: "8px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h4" gutterBottom>
          Upload your {exam.toUpperCase()} File
        </Typography>
        <TextField
          type="file"
          onChange={handleFileChange}
          variant="outlined"
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpload}
          disabled={!file}
        >
          Upload
        </Button>
      </Box>
    </Container>
  );
};

export default Uploader;
