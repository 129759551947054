import React from "react";
import "./Footer.css";

function Footer() {
  if (window.location.pathname === "/admin/soldcards/today") return null;
  return (
    <footer className="footer">
      <p>© 2024 www.resultcheckers.com All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;
