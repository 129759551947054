import React, { useState } from "react";
import axios from "axios";
import { TextField, CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Register.css";

const Register = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z]*$/.test(value)) {
      setFirstName(value);
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z]*$/.test(value)) {
      setLastName(value);
    }
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setPhoneNumber(value);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.trim());
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (password !== confirmPassword) {
      setLoading(false);
      toast.error("Passwords do not match");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}users/register`,
        {
          first_name:firstName,
          surname: lastName,
          gsm: phoneNumber,
          email,
          password,
          confirmPassword
        }
      );

      if (response.status === 200) {
        setLoading(false);
        toast.success("Registration successful!");
        return (window.location.href = "/login");
      } else {
        setLoading(false);
        toast.error("Registration failed. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      if(error?.response?.data?.message){
        return toast.error(error.response.data.message)
      }
      toast.error("An error occurred. Please try again.");
    }
  };

  return (
    <div className="register-container">
      <ToastContainer />

      <center>
        <br />
        <br />
        <br />
        <span
          style={{
            marginBottom: 10,
          }}
        >
          REGISTER
        </span>
        <br />
        <br />
        <form onSubmit={handleSubmit} className="register-form">
          <span className="my-1">
            <TextField
              label="First Name"
              variant="outlined"
              placeholder="Type in your first name"
              value={firstName}
              onChange={handleFirstNameChange}
              fullWidth
            />
          </span>
          <span className="my-1">
            <TextField
              label="Last Name"
              variant="outlined"
              placeholder="Type in your last name"
              value={lastName}
              onChange={handleLastNameChange}
              fullWidth
            />
          </span>
          <span className="my-1">
            <TextField
              label="Phone Number"
              variant="outlined"
              placeholder="Type in your phone number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              fullWidth
            />
          </span>
          <span className="my-1">
            <TextField
              label="Email Address"
              variant="outlined"
              placeholder="Type in your email address"
              value={email}
              onChange={handleEmailChange}
              fullWidth
            />
          </span>
          <span className="my-1">
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              placeholder="Type in your password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
            />
          </span>
          <span className="my-1">
            <TextField
              label="Confirm Password"
              type="password"
              variant="outlined"
              placeholder="Confirm your password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              fullWidth
            />
          </span>
          <span className="my-1">
            <button
              type="submit"
              className="register-button"
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Register"
              )}
            </button>
          </span>
        </form>
      </center>
    </div>
  );
};

export default Register;
