import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <div className="about-us-section">
      <h2>About Us</h2>
      <div className="about-us-content">
        <div className="about-us-text" style={{ fontFamily: "Quicksand" }}>
          <p>
            ResultCheckers is powered by Logic And Webs, a leading software
            organization dedicated to providing innovative solutions for
            academic result checking and token sales. We aim to simplify the
            process of accessing exam results for students and parents,
            ensuring a seamless and efficient experience.
          </p>
          <p>
            Our platform supports a variety of examinations, including WAEC,
            NECO, NABTEB, and more. With a focus on reliability and
            user-friendliness, ResultCheckers is your trusted partner in
            academic success.
          </p>
          <p>
            At Logic And Webs, we are committed to excellence and continuously
            strive to enhance our services to meet the evolving needs of our
            users.
          </p>
        </div>
        <div className="about-us-images">
          <img
            src="/img/aboutus/aboutus1.jpg"
            alt="Team working"
            className="about-image"
          />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
