import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import "./ProductCard.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ProductCard = ({ product, balance, token, setLoading }) => {
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const [count, setCount] = useState(0);
  const [cardLoading, setCardLoading] = useState(false);

  useEffect(() => {
    setCardLoading(true);
    axios
      .get(process.env.REACT_APP_BASE_URL+`products/count/${product.product}`)
      .then((response) => {
        setCount(response.data.count);
        setCardLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setCardLoading(false);
      });
  }, [product.product]);

  const handleBuy = async () => {
    setLoading(true);
    console.log(product.product)
    const totalCost = product.price * quantity;
    if (totalCost > balance) {
      toast.error("Insufficient balance");
      setQuantity(1);
      setLoading(false);
      return
    }

    try {
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: process.env.REACT_APP_BASE_URL+"getpins/purchasewallet",
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
        data: { examType: product.product, quantity: quantity },
      };
      const response = await axios.request(config);
      console.log(response.data);
      if (response.status === 200 || response.status === 201) {
        const {_id} = response.data;
        toast.success("Purchase successful!");
        navigate(`/transaction/${_id}`);
      }
    } catch (error) {
      console.log(error);
      toast.error("Purchase failed", error.response.data.message);
      return setLoading(false);

    }
  };

  const handleIncrement = () => {
    setQuantity(Math.max(1, quantity + 1));
  };

  const handleDecrement = () => {
    setQuantity(Math.max(1, quantity - 1));
  };

  if (cardLoading) {
    return (
      <div className="card-loader">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Card className="product-card">
      <div className={`image-container ${count < 1 ? "out-of-stock" : ""}`}>
        <center>
          <CardMedia
            component="img"
            alt={product.name}
            height="140"
            image={product.image}
            title={product.name}
            className={count < 1 ? "grayscale" : ""}
          />
          {count < 1 && <div className="ribbon">Out of Stock</div>}
        </center>
      </div>
      <center>
        <span style={{ fontWeight: "bolder" }}>{product.name}</span>
      </center>
      <CardContent>
        <center>
          <Typography variant="body2" color="text.secondary">
            {product.description}
          </Typography>
          <Typography variant="h7" component="div" className="product-price">
            NGN {product.price}
          </Typography>
          <div className="quantity-selector">
            <IconButton
              onClick={handleDecrement}
              style={{ backgroundColor: "black", color: "white" }}
              disabled={count < 1}
            >
              <Remove />
            </IconButton>
            <TextField
              type="number"
              value={quantity}
              inputProps={{ min: 1 }}
              style={{
                width: 60,
                textAlign: "center",
                margin: "0 10px",
              }}
              disabled={count < 1}
            />
            <IconButton
              onClick={handleIncrement}
              style={{ backgroundColor: "black", color: "white" }}
              disabled={count < 1}
            >
              <Add />
            </IconButton>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleBuy}
            className="buy-button"
            style={{ marginTop: "10px", width: "100%" }}
            disabled={count < 1}
          >
            Buy
          </Button>
        </center>
      </CardContent>
    </Card>
  );
};

export default ProductCard;

 
