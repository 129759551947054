// src/components/SoldCardsCount.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./SoldCardsCount.css";
import ProductCount from "../../../components/ProductCount/ProductCount";

const SoldCardsCount = () => {
  const [counts, setCounts] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BASE_URL + "soldcards/count/today")
      .then((response) => {
        setCounts(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const handleRedirect = (exam, time) => {
    navigate(`/admin/soldcards/table?exam=${exam}&time=${time}`);
  };

  if (loading) {
    return <div className="loader">Loading...</div>;
  }

  return (
    <div className="sold-cards-container">
      <h2>Sold Cards Count</h2>
      <ProductCount exam="waecresultcheckers"/>
      <ProductCount exam="necotokens"/>
      <ProductCount exam="nabtebresultcheckers"/>
      <ProductCount exam="waecgce"/>
      <ProductCount exam="nabtebgce"/>
      <div className="count-section">
        <h3>Today</h3>
          <div onClick={() => handleRedirect("waecresultcheckers", "today")}>
            Waec resultcheckers: {counts?.today?.waecresultcheckers}
          </div>
        <div onClick={() => handleRedirect("necotokens", "today")}>
          Neco resultcheckers: {counts?.today?.necotokens}
        </div>
        <div onClick={() => handleRedirect("nabtebresultcheckers", "today")}>
          Nabteb resultcheckers: {counts?.today?.nabtebresultcheckers}
        </div>
        <div onClick={() => handleRedirect("waecgce", "today")}>
          Waec GCE: {counts?.today?.waecgce}
        </div>
        <div onClick={() => handleRedirect("nabtebgce", "today")}>
          Nabteb GCE: {counts?.today?.nabtebgce}
        </div>
      </div>
      <div className="count-section">
        <h3>Yesterday</h3>
        <div onClick={() => handleRedirect("waecresultcheckers", "yesterday")}>
          Waec resultcheckers: {counts?.yesterday?.waecresultcheckers}
        </div>
        <div onClick={() => handleRedirect("necotokens", "yesterday")}>
          Neco resultcheckers: {counts?.yesterday?.necotokens}
        </div>
        <div
          onClick={() => handleRedirect("nabtebresultcheckers", "yesterday")}
        >
          Nabteb resultcheckers: {counts?.yesterday?.nabtebresultcheckers}
        </div>
        <div onClick={() => handleRedirect("waecgce", "yesterday")}>
          Waec GCE: {counts?.yesterday?.waecgce}
        </div>
        <div onClick={() => handleRedirect("nabtebgce", "yesterday")}>
          Nabteb GCE: {counts?.yesterday?.nabtebgce}
        </div>
      </div>
      <div className="count-section">
        <h3>This Month</h3>
        <div onClick={() => handleRedirect("waecresultcheckers", "month")}>
          Waec resultcheckers: {counts?.month?.waecresultcheckers}
        </div>
        <div onClick={() => handleRedirect("necotokens", "month")}>
          Neco resultcheckers: {counts?.month?.necotokens}
        </div>
        <div onClick={() => handleRedirect("nabtebresultcheckers", "month")}>
          Nabteb resultcheckers: {counts?.month?.nabtebresultcheckers}
        </div>
        <div onClick={() => handleRedirect("waecgce", "month")}>
          Waec GCE: {counts?.month?.waecgce}
        </div>
        <div onClick={() => handleRedirect("nabtebgce", "month")}>
          Nabteb GCE: {counts?.month?.nabtebgce}
        </div>
      </div>
      <div className="count-section">
        <h3>This Year</h3>
        <div onClick={() => handleRedirect("waecresultcheckers", "year")}>
          Waec resultcheckers: {counts?.year?.waecresultcheckers}
        </div>
        <div onClick={() => handleRedirect("necotokens", "year")}>
          Neco resultcheckers: {counts?.year?.necotokens}
        </div>
        <div onClick={() => handleRedirect("nabtebresultcheckers", "year")}>
          Nabteb resultcheckers: {counts?.year?.nabtebresultcheckers}
        </div>
        <div onClick={() => handleRedirect("waecgce", "year")}>
          Waec GCE: {counts?.year?.waecgce}
        </div>
        <div onClick={() => handleRedirect("nabtebgce", "year")}>
          Nabteb GCE: {counts?.year?.nabtebgce}
        </div>
      </div>
    </div>
  );
};

export default SoldCardsCount;
