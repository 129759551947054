import { padding } from "@mui/system";
import ConfirmRow from "./Components/ConfirmRow";

const Confirm = () => {
    return (
      <div className="container" style={styles.container}>
        <div className="" style={{ width: "40%" }}>
          <center>
            <img src="/img/neco.jpg" alt="neco" style={styles.img} />
          </center>
          <div>
            <div style={styles.confirm}
            >
              <center>
                <span style={styles.h1} 
                >
                  <h1>Payment Confirmation</h1>
                </span>
              </center>
            </div>
            <div className="confirmation-details">
              <ConfirmRow name="Units" value="10" />
            </div>
          </div>
        </div>
      </div>
    );
};

const styles = {
     container: {
        display: "flex",
        padding:10,
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center", // Add this line to center align the text
    },
   
    img: {
        width: 100,
        height: 70,
    },
    confirm: {
        flex: 1,
        marginTop: 10,
        backgroundColor: "black",
        height: 50,
        justifyItems: "center",
        alignItems: "center",
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
    },
    h1: {
        fontWeight: "500",
        fontSize: 24,
        padding: 4,
        marginBottom: 4,
        color: "white",
        display: "inline-block",
    },
    details: {
        backgroundColor: "black",
        height: 50,
        justifyItems: "center",
        alignItems: "center",
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
    },
}

export default Confirm;
