import axios from "axios";
import { useEffect, useState } from "react";

const ProductCount = ({exam}) =>{
    const [Count, setCount] = useState();
    useEffect(() =>{
        axios.get(process.env.REACT_APP_BASE_URL+`products/count/${exam}`)
             .then(res =>{
                setCount(res.data.count)
             })
    }, [exam])
    return(
        <div style={{alignSelf:'flex-start'}}>
           <span style={{fontWeight:'200'}}> {`${exam.toString().toUpperCase()} `}</span>
            <span style={{fontWeight:'800'}}>{`${Count}`}</span>
        </div>
    )
}

export default ProductCount;