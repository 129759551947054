import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./account.css";
import ProductGrid from "../../components/ProductGrid";
import TransactionHistory from "../../components/TransactionHistory";
import FundWallet from "../../components/FundWallet";
import FundWithCard from "../../components/FundWithCard";
import { CreditCard, Logout, ShoppingBasket } from "@mui/icons-material";
import createPaystackAccount from "../../utils/createPaystackAccount";

const Account = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [backendOnline, setBackendOnline] = useState(false);
  const [gridToken, setGridToken] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("Products");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login");
          return;
        }       
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL + "users/profile",
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setProfile(response.data);
        setLoading(false);
        setBackendOnline(true);
        setGridToken(token);
      } catch (error) {
        setLoading(false);
        toast.error("Failed to fetch profile");
        return navigate("/login");
      }
    };

    fetchProfile();
  }, []);
  console.log(profile)

  // if(!backendOnline){
  //   return (
  //     <div>
  //       Server is currently having a downtime, try again later
  //     </div>
  //   )
  // }

  const handleLogout = () => {
    localStorage.removeItem("token");
    toast.success("Logout successful!");
    return (window.location.href = "/login");
  };

  const handleFundWallet = async () => {
    createPaystackAccount(
      {
        email: profile?.email,
        first_name: profile?.first_name,
        middle_name: profile?.last_name,
        last_name: profile?.last_name,
        phone: profile?.phone,
        preferred_bank: "wema-bank",
        country: "NG",
      },
      setLoading
    );
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    setDrawerOpen(false);
  };

  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress />
        <span>Loading....</span>
      </div>
    );
  }

  const menuItems = [
    "Products",
    "Transaction History",
    // "Fund Wallet with Bank",
    "Fund With Card",
    "Logout",
  ];

  const getBankLogo = (bankName) => {
    const logos = {
      "Wema Bank": "/img/banks/wemabank.png",
      "Test Bank": "/img/banks/wemabank.png",
      // Add more bank mappings here
    };
    return logos[bankName] || "/img/default-bank.png";
  };

  console.log(profile.paystack?.dedicated_account?.bank?.name);
  const bankAccountCard = profile?.paystack ? (
    <Card className="bank-account-card">
      <CardContent>
        {/* <CardMedia
          component="img"
          alt={profile.paystack?.dedicated_account?.bank.name}
          height="40"
          image={getBankLogo(profile?.paystack?.dedicated_account?.bank.name)}
          title={profile?.paystack?.dedicated_account?.bank.name}
        /> */}
        <Typography variant="body2" component="div">
          {/* {profile.bankAccounts[0].accountName} */}
          <span>
            <sm>Pay to </sm>
          </span>
          <span>{profile?.paystack?.dedicated_account?.account_name}</span>
        </Typography>
        <Typography
          variant="body2"
          color="textSecondary"
          style={{ justifyContent: "space-between" }}
        >
          <span style={{ marginRight: 10 }}>
            {profile?.paystack?.dedicated_account?.bank.name}
          </span>
          <span style={{ fontWeight: "bolder", marginLeft: 6 }}>
            {/* Account Number -{" "} */}
            {profile?.paystack?.dedicated_account?.account_number}
          </span>
        </Typography>

        <Typography
          variant="body1"
          color="textPrimary"
          style={{ fontWeight: "bold", marginTop: 2 }}
        >
          PAY INTO THIS BANK ACCOUNT TO GET YOUR WALLET FUNDED
        </Typography>
      </CardContent>
    </Card>
  ) : (
    <Button variant="contained" color="primary" onClick={handleFundWallet}>
      Fund Wallet with Bank
    </Button>
  );

  return (
    <div>
      <AppBar position="static" style={{backgroundColor:"black"}}>
        <ToastContainer />

        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <img src="/img/logo.png" width={80} alt="resultcheckers"/>
        </Toolbar>
      </AppBar>
      <div className="account-container">
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <List onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            {menuItems.map((text, index) => (
              <ListItem
                button
                key={index}
                onClick={() => handleMenuClick(text)}
              >
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Drawer>
        <div className="content">
          <div className="header">
            <div className="welcome-message">
              <h2>Welcome {profile.first_name}</h2>
            </div>
            <div className="button-group">
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleMenuClick("Products")}
              >
                <ShoppingBasket/>
                PRODUCTS
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleMenuClick("Fund With Card")}
              >
                <CreditCard/>
                Fund With Card
              </Button>
              {bankAccountCard}
              <Button
                variant="contained"
                color="secondary"
                onClick={handleLogout}
              >
                <Logout/>
                Logout
              </Button>
            </div>
          </div>
          <center style={{ fontSize: 20, fontWeight: "bolder" }}>
            Balance - NGN{profile.balance}
          </center>
          {selectedMenu === "Products" && (
            <ProductGrid
              balance={profile.balance}
              token={gridToken}
              setLoading={setLoading}
            />
          )}

          {selectedMenu === "Transaction History" && <TransactionHistory email={profile.email} />}

          {selectedMenu === "Fund Wallet with Bank" && (
            <FundWallet banks={profile.bankAccounts} />
          )}
          {selectedMenu === "Fund With Card" && (
                <FundWithCard 
                  profile={profile} 
                  setLoading={setLoading}
                />
          )}
          {selectedMenu === "Logout" && handleLogout()}
        </div>
      </div>
    </div>
  );
};

export default Account;
