import axios from "axios";
import React, { useState, useLayoutEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import HomeIcon from "@mui/icons-material/Home";
import Pending from "./Pending";
import "./Transaction.css";
import Loading from "../../components/Loading";

const Transaction = () => {
  const { id } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null)
  const navigate = useNavigate();

  useLayoutEffect(() => {
    const fetchTransaction = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          process.env.REACT_APP_BASE_URL+`transactions/${id}`
        );
        const data = response.data;
        setTransaction(data);
        setStatus(data.status)
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transaction:", error);
        setLoading(false);
      }
    };

    fetchTransaction();
  }, [id]);

  const handlePrint = () => {
    window.print();
  };

  const handleBackHome = () => {
    navigate("/");
  };

  if (loading || transaction === null || !transaction.pins) {
    return <Loading open={loading} />;
  }

  if (status === "initiated") {
    return <Pending id={id} data={transaction} />;
  }

  const totalAmount = transaction.pins.reduce(
    (acc, pin) => acc + transaction.price,
    0
  );

  return (
    <div className="transaction-container">
      <div className="transaction-header">
        <Typography variant="h4" gutterBottom>
          Exam Pins
        </Typography>
        <Typography variant="subtitle1" gutterBottom>
          Transaction ID: {transaction._id}
        </Typography>
      </div>
      <TableContainer component={Paper} className="table-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>#</strong>
              </TableCell>
              <TableCell>
                <strong>Card Type</strong>
              </TableCell>
              <TableCell>
                <strong>Serial</strong>
              </TableCell>
              <TableCell>
                <strong>Pin</strong>
              </TableCell>
              <TableCell>
                <strong>Price</strong>
              </TableCell>
              <TableCell>
                <strong>Date</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transaction.pins.map((pin, index) => (
              <TableRow key={pin._id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{transaction.exam}</TableCell>
                <TableCell>{pin.serials}</TableCell>
                <TableCell>{pin.pins}</TableCell>
                <TableCell>NGN {transaction.price}</TableCell>
                <TableCell>
                  {new Date(transaction.date).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
            <TableRow style={{ backgroundColor: "black", color: "white" }}>
              <TableCell colSpan={4}></TableCell>
              <TableCell>
                <strong style={{ color: "white", fontSize: "1.2rem" }}>
                  Total:
                </strong>
              </TableCell>
              <TableCell>
                <strong style={{ color: "white", fontSize: "1.2rem" }}>
                  NGN {totalAmount}
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <div className="transaction-actions">
        <Button
          variant="contained"
          color="primary"
          startIcon={<PrintIcon />}
          onClick={handlePrint}
        >
          Print
        </Button>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<HomeIcon />}
          onClick={handleBackHome}
        >
          Back Home
        </Button>
      </div>
    </div>
  );
};

export default Transaction;
