import React from "react";
import { Image } from "react-bootstrap";
import "./ProductItem.css";

const ProductItem = ({ product }) => {
  console.log(product)
  return (
    <li
      key={product.id}
      className={`product-item ${
        product.available? "available" : "not-available"
      }`}
      onClick={() =>
          product.available &&
        (window.location.href = `/product/${product.product}`)
      }
    >
      <div className="image-container">
        <Image
          src={product.image}
          width={150}
          height={100}
          className={product.count === 0 ? "grayscale" : ""}
        />
        {
        // product.count === 0 && (
         !product.available && (
          <div className="out-of-stock-text">OUT OF STOCK</div>
        )}
      </div>
      <div className="product-info">
        <span className="product-name">{product.name}</span>
      </div>
      <span className="product-price">NGN {product.price}</span>
    </li>
  );
};

export default ProductItem;
