const clientProducts = [
  {
    product: "waecresultcheckers",
    name: "Waec ResultCheckers",
    price: parseInt(process.env.REACT_APP_WAEC_PRICE),
    image: "/img/waec.jpg",
    title: "WAEC Online Result Checker",
    description:
      "This is a WAEC resultchecker which can be used to check WAEC results on WAEC website.",
    instructions: [
      "Go to the WAEC result checking portal at https://waecdirect.org",
      "Enter your WAEC Examination Number, Year and Type in the required space",
      "Enter the WAEC result checker serial number and pin in the required space",
      "Click on the Submit button to view your WAEC result",
    ],
    keywords: {
      informational: [
        "How to check WAEC results online",
        "WAEC result checker guide",
        "WAEC result checker portal",
        "WAEC result verification process",
        "Steps to check WAEC results",
        "WAEC result checker without scratch card",
        "WAEC result checker with mobile app",
        "Common errors in WAEC result checking",
        "How to retrieve lost WAEC results",
        "WAEC result checker FAQs",
        "WAEC result checker customer support",
        "WAEC result checker issues and solutions",
        "Free WAEC result checker",
        "How to interpret WAEC results",
        "WAEC result checker for schools",
        "WAEC result checker alternatives",
        "How to check WAEC results via SMS",
        "WAEC result checker updates",
        "WAEC result release dates",
        "WAEC result checker login problems",
        "WAEC result checker 2024",
        "WAEC result checker benefits",
        "How to check WAEC results from previous years",
        "WAEC result checker for private candidates",
        "WAEC result checker for international students",
        "WAEC result checker tutorial videos",
        "WAEC result checker for different countries",
        "WAEC result checker security tips",
        "Understanding WAEC grading system",
        "How to appeal WAEC results",
      ],
      commercial: [
        "Buy WAEC result checker",
        "WAEC result checker sales",
        "Cheap WAEC result checker",
        "WAEC result checker vendors",
        "WAEC result checker discount codes",
        "WAEC result checker reviews",
        "WAEC result checker online payment",
        "WAEC result checker price comparison",
        "WAEC result checker best deals",
        "Top WAEC result checker brands",
        "WAEC result checker reseller programs",
        "WAEC result checker wholesale",
        "WAEC result checker bulk purchase",
        "WAEC result checker gift cards",
        "WAEC result checker subscription plans",
      ],
      scalable: [
        "WAEC result checker 2024",
        "WAEC result checker 2025",
        "WAEC result checker Nigeria",
        "WAEC result checker Ghana",
        "WAEC result checker Android app",
        "WAEC result checker iPhone app",
      ],
    },
    available: true,
  },
  {
    product: "necotokens",
    name: "Neco Tokens",
    price: parseInt(process.env.REACT_APP_NECO_PRICE),
    image: "/img/necotoken.jpg",
    title: "NECO Result Token",
    description:
      "This is a NECO result token which can be used to check NECO results on the NECO website.",
    instructions: [
      "Go to the NECO result checking portal at [NECO Result Checker](https://result.neco.gov.ng/)",
      "Enter your token and examination year in the required spaces",
      "Click on the Check Result button to view your NECO result",
    ],
    keywords: {
      informational: [
        "What is NECO token?",
        "How to use NECO token for result checking",
        "Benefits of NECO token for students",
        "NECO token purchase guide",
        "How to generate NECO token",
        "NECO result checking process",
        "Steps to check NECO results online",
        "Common issues with NECO result checkers",
        "NECO result checkers troubleshooting guide",
        "NECO token vs scratch card",
        "How to verify NECO results",
        "Understanding NECO token expiry",
        "NECO token for multiple results checking",
        "How to protect your NECO token",
        "Tips for faster NECO result checking",
        "NECO result checking mobile app",
        "History of NECO tokens",
        "How to retrieve lost NECO token",
        "NECO result checker security tips",
        "NECO result checking: FAQs",
        "How to check NECO results without token",
        "NECO token customer support",
        "How to validate NECO results online",
        "How to confirm NECO result authenticity",
        "NECO result checker compatibility issues",
        "How NECO tokens are generated",
        "Importance of NECO token in education",
        "Guide to NECO token usage for schools",
        "NECO result checking for international students",
        "How to purchase NECO token online",
        "NECO result checker user reviews",
        "Alternatives to NECO result checker",
        "How to use NECO token on mobile",
        "NECO result checking error messages",
        "Best practices for NECO result checking",
        "How to print NECO results",
        "How to interpret NECO results",
        "NECO token refund policy",
        "NECO result checker updates",
        "How to link NECO token to email",
        "How to store NECO token safely",
        "How to use NECO token for multiple exams",
        "NECO token and result checking privacy policy",
        "NECO token price comparison",
        "How to use NECO result checker offline",
      ],
      commercial: [
        "Buy NECO token online",
        "NECO token retailers",
        "Cheapest NECO token purchase",
        "NECO token discount codes",
        "NECO token bulk purchase",
        "NECO result checker subscription",
        "NECO token vendors",
        "Affordable NECO token",
        "NECO token promo offers",
        "Secure NECO token purchase",
        "NECO result checker software",
        "Online NECO result checking service",
        "NECO token sales",
        "Trusted NECO token suppliers",
        "Best NECO token deals",
        "NECO result checker premium",
        "Wholesale NECO tokens",
        "NECO token ecommerce sites",
        "NECO result checker packages",
        "NECO token price tracker",
        "NECO token free trials",
        "NECO result checker enterprise solutions",
        "Best NECO token websites",
        "NECO token online payment methods",
        "NECO result checker business solutions",
        "Custom NECO token orders",
        "NECO result checker analytics",
        "NECO token bundle offers",
        "Reliable NECO token providers",
        "NECO result checker professional services",
      ],
      scalable: [
        "NECO token purchase",
        "Generate NECO token",
        "Retrieve NECO token",
        "Validate NECO token",
        "How to use NECO token",
        "Buy NECO token",
        "Check NECO results",
        "Protect NECO token",
        "NECO result checker troubleshooting",
        "NECO result checker guide",
        "NECO result checker tips",
        "NECO result checker FAQs",
      ],
    },
    available: true,
  },
  {
    product: "nabtebresultcheckers",
    name: "NABTEB ResultCheckers",
    price: parseInt(process.env.REACT_APP_NABTEB_PRICE),
    image: "/img/nabteb.jpg",
    title: "NABTEB Result Checker",
    description:
      "This is a NABTEB result token which can be used to check NABTEB results on the NABTEB website.",
    instructions: [
      "Go to the NABTEB result checking portal at [NABTEB Result Checker](https://eworld.nabtebnigeria.org/)",
      "Enter your Candidate Number, Examination Type, Examination Year, and Token Serial Number in the required spaces",
      "Click on the Submit button to view your NABTEB result",
    ],
    keywords: {
      informational: [
        "What is NABTEB result checker?",
        "How to use NABTEB result checker",
        "Steps to check NABTEB results online",
        "NABTEB result checking guide",
        "Benefits of NABTEB result checker",
        "Common issues with NABTEB result checkers",
        "How to retrieve lost NABTEB results",
        "NABTEB result checker FAQs",
        "How to verify NABTEB results",
        "NABTEB result checker customer support",
        "NABTEB result checker troubleshooting",
        "Free NABTEB result checker",
        "How to interpret NABTEB results",
        "NABTEB result checker for schools",
        "NABTEB result checker alternatives",
        "How to check NABTEB results via SMS",
        "NABTEB result checker updates",
        "NABTEB result release dates",
        "NABTEB result checker login problems",
        "NABTEB result checker benefits",
        "How to check NABTEB results from previous years",
        "NABTEB result checker for private candidates",
        "NABTEB result checker for international students",
        "NABTEB result checker tutorial videos",
        "NABTEB result checker for different countries",
        "NABTEB result checker security tips",
        "Understanding NABTEB grading system",
        "How to appeal NABTEB results",
        "NABTEB result checker mobile app",
        "How to print NABTEB results",
        "Best practices for NABTEB result checking",
        "NABTEB result checking error messages",
        "How to confirm NABTEB result authenticity",
        "NABTEB result checker user reviews",
        "NABTEB result checker compatibility issues",
        "Guide to NABTEB result checking for schools",
        "How to protect your NABTEB result checker details",
        "History of NABTEB result checkers",
        "NABTEB result checking: FAQs",
      ],
      commercial: [
        "Buy NABTEB result checker online",
        "NABTEB result checker sales",
        "Cheap NABTEB result checker",
        "NABTEB result checker vendors",
        "NABTEB result checker discount codes",
        "NABTEB result checker reviews",
        "NABTEB result checker online payment",
        "NABTEB result checker price comparison",
        "NABTEB result checker best deals",
        "Top NABTEB result checker brands",
        "NABTEB result checker reseller programs",
        "NABTEB result checker wholesale",
        "NABTEB result checker bulk purchase",
        "NABTEB result checker gift cards",
        "NABTEB result checker subscription plans",
        "NABTEB result checker premium",
        "NABTEB result checker software",
        "Online NABTEB result checking service",
        "NABTEB result checker business solutions",
        "NABTEB result checker ecommerce sites",
        "Trusted NABTEB result checker suppliers",
        "Secure NABTEB result checker purchase",
        "NABTEB result checker promo offers",
        "NABTEB result checker enterprise solutions",
        "Custom NABTEB result checker orders",
        "Reliable NABTEB result checker providers",
      ],
      scalable: [
        "NABTEB result checker 2024",
        "NABTEB result checker 2025",
        "NABTEB result checker Nigeria",
        "NABTEB result checker Ghana",
        "NABTEB result checker Android app",
        "NABTEB result checker iPhone app",
        "NABTEB result checker tutorial",
        "NABTEB result checker troubleshooting guide",
        "NABTEB result checker tips",
        "How to use NABTEB result checker",
        "Buy NABTEB result checker",
        "NABTEB result checker FAQs",
      ],
    },
    available: true,
  },
  {
    product: "waecgce",
    name: "Waec GCE",
    price: parseInt(process.env.REACT_APP_WAECGCE_PRICE),
    image: "/img/waec_gce.jpg",
    title: "WAEC GCE Registration Pin",
    description:
      "This is a WAEC GCE registration pin which can be used to register WAEC GCE exam.",
    instructions: [
      "Visit the WAEC GCE registration portal",
      "Follow the registration instructions provided on the portal",
      "Enter the WAEC GCE registration pin when prompted",
    ],
    keywords: {
      informational: [
        "What is WAEC GCE?",
        "How to register for WAEC GCE",
        "WAEC GCE exam dates",
        "WAEC GCE timetable",
        "Benefits of WAEC GCE exams",
        "How to prepare for WAEC GCE",
        "WAEC GCE past questions and answers",
        "WAEC GCE result checking guide",
        "How to check WAEC GCE results online",
        "Common issues with WAEC GCE result checking",
        "WAEC GCE registration process",
        "WAEC GCE fees and payment methods",
        "WAEC GCE examination centers",
        "How to retrieve lost WAEC GCE registration details",
        "WAEC GCE result verification",
        "WAEC GCE result checker FAQs",
        "How to interpret WAEC GCE results",
        "WAEC GCE examination rules and regulations",
        "WAEC GCE study materials",
        "WAEC GCE exam preparation tips",
        "WAEC GCE for private candidates",
        "WAEC GCE vs regular WAEC exams",
        "How to appeal WAEC GCE results",
        "WAEC GCE grading system",
        "WAEC GCE for international students",
        "WAEC GCE exam format and structure",
        "WAEC GCE result release dates",
        "WAEC GCE registration deadlines",
        "WAEC GCE syllabus",
        "WAEC GCE exam practice tests",
        "How to improve WAEC GCE exam scores",
        "WAEC GCE result checker mobile app",
        "WAEC GCE result checker issues and solutions",
        "WAEC GCE result checker customer support",
        "Free WAEC GCE result checker",
        "How to print WAEC GCE results",
        "Best practices for WAEC GCE result checking",
        "WAEC GCE exam tips and tricks",
        "Understanding WAEC GCE examination process",
      ],
      commercial: [
        "Buy WAEC GCE result checker",
        "WAEC GCE result checker sales",
        "Cheap WAEC GCE result checker",
        "WAEC GCE result checker vendors",
        "WAEC GCE result checker discount codes",
        "WAEC GCE result checker reviews",
        "WAEC GCE result checker online payment",
        "WAEC GCE result checker price comparison",
        "WAEC GCE result checker best deals",
        "Top WAEC GCE result checker brands",
        "WAEC GCE result checker reseller programs",
        "WAEC GCE result checker wholesale",
        "WAEC GCE result checker bulk purchase",
        "WAEC GCE result checker gift cards",
        "WAEC GCE result checker subscription plans",
        "WAEC GCE result checker premium",
        "WAEC GCE registration online",
        "WAEC GCE registration fees",
        "WAEC GCE registration centers",
        "WAEC GCE result checker enterprise solutions",
        "Trusted WAEC GCE result checker suppliers",
        "Secure WAEC GCE result checker purchase",
        "WAEC GCE result checker promo offers",
        "WAEC GCE result checker ecommerce sites",
        "Custom WAEC GCE result checker orders",
        "Reliable WAEC GCE result checker providers",
      ],
      scalable: [
        "WAEC GCE result checker 2024",
        "WAEC GCE result checker 2025",
        "WAEC GCE result checker Nigeria",
        "WAEC GCE result checker Ghana",
        "WAEC GCE result checker Android app",
        "WAEC GCE result checker iPhone app",
        "WAEC GCE registration guide",
        "WAEC GCE result checker troubleshooting",
        "WAEC GCE result checker tips",
        "How to use WAEC GCE result checker",
        "Buy WAEC GCE result checker",
        "WAEC GCE result checker FAQs",
      ],
    },
    available: false,
  },
  {
    product: "nabtebgce",
    name: "Nabteb GCE",
    price: parseInt(process.env.REACT_APP_NABTEBGCE_PRICE),
    image: "/img/nabtebgce.jpg",
    title: "NABTEB GCE Registration Pin",
    description:
      "This is a NABTEB GCE registration pin which can be used to register NABTEB GCE exams.",
    instructions: [
      "Visit the NABTEB GCE registration portal",
      "Follow the registration instructions provided on the portal",
      "Enter the NABTEB GCE registration pin when prompted",
    ],
    keywords: {
      informational: [
        "What is NABTEB GCE?",
        "How to register for NABTEB GCE",
        "NABTEB GCE exam dates",
        "NABTEB GCE timetable",
        "Benefits of NABTEB GCE exams",
        "How to prepare for NABTEB GCE",
        "NABTEB GCE past questions and answers",
        "NABTEB GCE result checking guide",
        "How to check NABTEB GCE results online",
        "Common issues with NABTEB GCE result checking",
        "NABTEB GCE registration process",
        "NABTEB GCE fees and payment methods",
        "NABTEB GCE examination centers",
        "How to retrieve lost NABTEB GCE registration details",
        "NABTEB GCE result verification",
        "NABTEB GCE result checker FAQs",
        "How to interpret NABTEB GCE results",
        "NABTEB GCE examination rules and regulations",
        "NABTEB GCE study materials",
        "NABTEB GCE exam preparation tips",
        "NABTEB GCE for private candidates",
        "NABTEB GCE vs regular NABTEB exams",
        "How to appeal NABTEB GCE results",
        "NABTEB GCE grading system",
        "NABTEB GCE for international students",
        "NABTEB GCE exam format and structure",
        "NABTEB GCE result release dates",
        "NABTEB GCE registration deadlines",
        "NABTEB GCE syllabus",
        "NABTEB GCE exam practice tests",
        "How to improve NABTEB GCE exam scores",
        "NABTEB GCE result checker mobile app",
        "NABTEB GCE result checker issues and solutions",
        "NABTEB GCE result checker customer support",
        "Free NABTEB GCE result checker",
        "How to print NABTEB GCE results",
        "Best practices for NABTEB GCE result checking",
        "NABTEB GCE exam tips and tricks",
        "Understanding NABTEB GCE examination process",
      ],
      commercial: [
        "Buy NABTEB GCE result checker",
        "NABTEB GCE result checker sales",
        "Cheap NABTEB GCE result checker",
        "NABTEB GCE result checker vendors",
        "NABTEB GCE result checker discount codes",
        "NABTEB GCE result checker reviews",
        "NABTEB GCE result checker online payment",
        "NABTEB GCE result checker price comparison",
        "NABTEB GCE result checker best deals",
        "Top NABTEB GCE result checker brands",
        "NABTEB GCE result checker reseller programs",
        "NABTEB GCE result checker wholesale",
        "NABTEB GCE result checker bulk purchase",
        "NABTEB GCE result checker gift cards",
        "NABTEB GCE result checker subscription plans",
        "NABTEB GCE result checker premium",
        "NABTEB GCE registration online",
        "NABTEB GCE registration fees",
        "NABTEB GCE registration centers",
        "NABTEB GCE result checker enterprise solutions",
        "Trusted NABTEB GCE result checker suppliers",
        "Secure NABTEB GCE result checker purchase",
        "NABTEB GCE result checker promo offers",
        "NABTEB GCE result checker ecommerce sites",
        "Custom NABTEB GCE result checker orders",
        "Reliable NABTEB GCE result checker providers",
      ],
      scalable: [
        "NABTEB GCE result checker 2024",
        "NABTEB GCE result checker 2025",
        "NABTEB GCE result checker Nigeria",
        "NABTEB GCE result checker Ghana",
        "NABTEB GCE result checker Android app",
        "NABTEB GCE result checker iPhone app",
        "NABTEB GCE registration guide",
        "NABTEB GCE result checker troubleshooting",
        "NABTEB GCE result checker tips",
        "How to use NABTEB GCE result checker",
        "Buy NABTEB GCE result checker",
        "NABTEB GCE result checker FAQs",
      ],
    },
    available: false,
  },
];
export default clientProducts;