import axios from "axios";

const addTransaction = async (
exam,
email,
phone,
price,
quantity,
amount,
paymentMethod
) => {
    console.log(exam, email, phone, price, quantity, amount, paymentMethod);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}transactions/add`,
      {
        exam,
        email,
        phone,
        price,
        quantity,
        totalAmount: amount,
        paymentMethod,
        type:"purchased"
      }
    );
    return response.data; // This will be the objectId
  } catch (error) {
    console.error("Error adding pending transaction:", error);
    throw error;
  }
};

export default addTransaction;
