// src/components/ProductList/ProductList.js
import React, { useContext } from "react";
import { ProductContext } from "../../context/products";
import ProductItem from "./ProductItem";
import "./ProductList.css";

const ProductList = () => {
  const products = useContext(ProductContext);
  // console.log(products)

  return (
    <div className="product-list">
      <h2>Our Products</h2>
      <ul className="product-items">
        {products.map((product) => (
          <ProductItem key={product.id} product={product} />
        ))}
      </ul>
    </div>
  );
};

export default ProductList;
