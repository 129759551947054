import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home/Home";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Product from "./pages/Product/product";
import Confirm from "./pages/Confirm/Confirm";
import Login from "./pages/Login";
import Account from "./pages/Account";
import { ProductContext } from "./context/products";
import axios from "axios";
import { CircularProgress, ThemeProvider } from "@mui/material";
import theme from "./theme";
import Transaction from "./pages/Transaction";
import Uploader from "./pages/Admin/uploader";
import Maintenance from "./components/Maintenance";
import Vpay from "./pages/Vpay/Vpay";
import Register from "./pages/Register/Register";
import ContactUs from "./pages/Contact/Contact";
import WaecResultCheckers from "./pages/Blog/Waecresultcheckers/Waecresultcheckers";
import NecoTokens from "./pages/Blog/Necotokens/Necotokens";
import SoldCardsCountToday from "./pages/Admin/soldcards/today";
import BroadcastEmail from "./pages/Admin/sendMail";
import ForgotPassword from "./pages/ForgottenPassword";
import ResetPassword from "./pages/ResetPassword";
import SoldCardsTable from "./pages/Admin/soldcards/SoldCardsTable/SoldCardsTable";
import SoldCardsByEmail from "./pages/Admin/soldcards/SoldCardsTable/SoldCardsByEmail";
import { trackPage } from "./analytics";
import clientProducts from "./utils/client-product";

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname + location.search;
    trackPage(page);
  }, [location]);
};

const App = () => {
  // const [products, setProducts] = useState([]);
  // const [backend, setBackend] = useState(false);

  // useEffect(() => {
  //   axios
  //     .get(process.env.REACT_APP_BASE_URL + "products")
  //     .then((res) => {
  //       setProducts(res.data);
  //       setBackend(true);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setBackend(false);
  //     });
  // }, []);

  // if (clientProducts.length === 0) {
  //   return (
  //     <div>
  //       <center>
  //         <p>
  //           <CircularProgress />
  //         </p>
  //         <p>Loading....</p>
  //       </center>
  //     </div>
  //   );
  // }

  // if (!backend) {
  //   return <Maintenance />;
  // }

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <TrackPages />
        <ProductContext.Provider value={clientProducts}>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/vpay" element={<Vpay />} />
            <Route exact path="/confirm" element={<Confirm />} />
            <Route path="/product/:exam" element={<Product />} />
            <Route path="/login" element={<Login />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/register" element={<Register />} />
            <Route path="/account" element={<Account />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/transaction/:id" element={<Transaction />} />
            <Route path="/admin/uploader/:exam" element={<Uploader />} />
            <Route path="/admin/soldcards" element={<SoldCardsCountToday />} />
            <Route path="/admin/soldcards/table" element={<SoldCardsTable />} />
            <Route
              path="/admin/soldcards/email"
              element={<SoldCardsByEmail />}
            />
            <Route path="/admin/sendmail" element={<BroadcastEmail />} />
            <Route
              path="/waecresultcheckers"
              element={<WaecResultCheckers />}
            />
            <Route path="/necotokens" element={<NecoTokens />} />
          </Routes>
          <Footer />
        </ProductContext.Provider>
      </Router>
    </ThemeProvider>
  );
};

const TrackPages = () => {
  usePageTracking();
  return null;
};

export default App;
