import React, { useContext } from "react";
import ProductCard from "../ProductCard";
import { ProductContext } from "../../context/products";
import "./ProductGrid.css";

const ProductGrid = ({ balance, token, setLoading }) => {
  const products = useContext(ProductContext);

  return (
    <div className="product-grid">
      {products.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          balance={balance}
          token={token}
          setLoading={setLoading}
        />
      ))}
    </div>
  );
};

export default ProductGrid;
