const initializeMonnifyPayment = (amount, email, phone, productTitle, objectId) => {

  if (typeof window.MonnifySDK !== "undefined") {
    console.log(process.env.REACT_APP_MONIFY_API_KEY);
    console.log(process.env.REACT_APP_MONIFY_CONTRACT_CODE);
    try {
    window.MonnifySDK.initialize({
      amount: amount,
      currency: "NGN",
      reference: objectId,
      customerName: email,
      customerEmail: email,
      customerMobileNumber: phone,
      apiKey: process.env.REACT_APP_MONIFY_API_KEY,
      contractCode: process.env.REACT_APP_MONIFY_CONTRACT_CODE,
      paymentDescription: `Payment for ${productTitle}`,
      isTestMode: true,
      paymentMethods: ["ACCOUNT_TRANSFER"],
      onComplete: (response) => {
        //  console.log("Payment completed -", response);
        //  console.log(objectId)
         window.location.href = `/transaction/${response.paymentReference}`;
        // Handle payment completion (e.g., show success message, update backend, etc.)
      },
      onClose: () => {
        console.log("Payment popup closed");
        // Handle payment popup close
      },
    }); 
  } catch (error) {
    console.error("Error initializing Monnify payment:", error);
  }
} else {
    console.error("MonnifySDK is not loaded");
  }
};

export default initializeMonnifyPayment;
