import React from "react";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";

const partners = [
  {
    name: "WAEC",
    logo: "/img/partners/waec.png",
    url: "waecdirect.org",
  },
  {
    name: "NECO",
    logo: "/img/partners/neco.png",
    url: "neco.gov.com",
  },
  {
    name: "MONNIFY",
    logo: "/img/partners/monnify.png",
    url: "monnify.com",
    // testimonial:
    //   "It has broadened our horizons and helped me advance my career. The community is incredibly supportive.",
  },
  {
    name: "FLUTTERWAVE",
    logo: "/img/partners/flutterwave.png",
    url: "flutterwave.com",
  },
  {
    name: "NABTEB",
    logo: "/img/partners/nabteb.png",
    url: "nabteb.gov.com",
  },
];

const Partners = () => (
  <Box sx={{ py: 8, px: 4, textAlign: "center" }}>
    <Typography variant="h6" color="textSecondary">
      More than 50+ brands trust us
    </Typography>
    <Typography variant="h3" color="textPrimary" gutterBottom>
      Trusted by Leading Brands
    </Typography>
    <Typography variant="body1" color="textSecondary" paragraph>
      From innovative startups to Fortune 500 companies, our client list spans a
      spectrum of sectors, each with unique challenges that we've successfully
      navigated.
    </Typography>
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 4,
      }}
    >
      {partners.map((partner, index) => (
        <Card key={index} sx={{ maxWidth: 200, backgroundColor: "#FAFAFA" }}>
          <CardContent>
            <img
              src={partner.logo}
              alt={`${partner.name} logo`}
              style={{ width: "100%", marginBottom: 8 }}
            />
            <Typography variant="body2" color="textSecondary">
              {partner.url}
            </Typography>
            {partner.testimonial && (
              <Typography variant="body2" color="textPrimary" mt={2}>
                &quot;{partner.testimonial}&quot;
              </Typography>
            )}
          </CardContent>
        </Card>
      ))}
    </Box>
    <Button variant="outlined" sx={{ mt: 4 }}>
      See all projects
    </Button>
  </Box>
);

export default Partners;
