import React from "react";
import "./FlutterwaveButton.css";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { CreditCard } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import addFunding from "../utils/addFunding";

const FlutterwaveButton = ({ setLoading, data }) => {
  const navigate = useNavigate();

  const config = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: "",
    amount: data.amount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: data.email,
      phone_number: data.phone,
    },
    customizations: {
      title: "Resultcheckers wallet funding",
      description: data.product,
      logo: "https://assets.piedpiper.com/logo.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const handlePaymentClick = async () => {
    setLoading(true);
    if (!data.email || !data.phone) {
      setLoading(false);
      alert("Please ensure both email and phone number are provided.");
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(data.email)) {
      setLoading(false);
      alert("Please enter a valid email address.");
      return;
    }

    try {
      const response = await addFunding(
        data.exam,
        data.email,
        data.phone,
        data.price,
        data.quantity,
        data.amount,
        "flutterwave"
      );
      const objectId = response; // Adjust based on actual response
      console.log("objectId:", response);

      config.tx_ref = objectId; // Set the transaction reference
      setLoading(false);
      handleFlutterPayment({
        callback: (response) => {
          console.log(response);
          if (response.status === "successful") {
            setLoading(true);
            setTimeout(() => {
              // navigate(`/account`);
              setLoading(false)
              return window.location.reload();
            }, 3000); // 3-second delay before navigating
          }
          closePaymentModal(); // this will close the modal programmatically
        },
        onClose: () => {},
      });
    } catch (error) {
      console.error("Error initializing payment:", error);
    }
  };

  return (
    <button
      onClick={handlePaymentClick}
      className="payment-button card-payment"
    >
      <CreditCard style={{ marginRight: 8 }} />
      Pay with Flutterwave
    </button>
  );
};

export default FlutterwaveButton;
