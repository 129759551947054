import React from "react";
import "./fundWallet.css";

const BankCard = ({ bank }) => {
  const bankLogos = {
    "Wema bank": "/img/banks/wemabank.png",
    // Add other banks here
  };

  const logo = bankLogos[bank.bankName] || "/img/default-bank.png";

  return (
    <div className="bank-card">
      <img src={logo} alt={`${bank.bankName} logo`} className="bank-logo" />
      <div className="bank-info">
        <div className="bank-name">{bank.bankName}</div>
        <div className="account-name">{bank.accountName}</div>
        <div className="account-number">{bank.accountNumber}</div>
      </div>
    </div>
  );
};

const FundWallet = ({ banks }) => {
  return (
    <div className="fund-wallet">
      <h2>Fund Wallet</h2>
      <p>You can fund your wallet with any of the bank accounts shown below:</p>
      <div className="bank-cards">
        {banks.map((bank, index) => (
          <BankCard key={index} bank={bank} />
        ))}
      </div>
    </div>
  );
};

export default FundWallet;
