import axios from "axios";
import { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import "./Pending.css";

const Pending = ({ id, data }) => {
  const [transaction, setTransaction] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchTransaction = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL+`/api/transaction/${id}`
      );
      const data = response.data;
      setTransaction(data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transaction:", error);
      setLoading(false);
    }
  };

  console.log(data)
  useEffect(() => {
     const fetch = async () => {
       try {
         const response = await axios.get(
           `http://localhost:5000/api/transaction/${id}`
         );
         const data = response.data;
         setTransaction(data);
         setLoading(false);
       } catch (error) {
         console.error("Error fetching transaction:", error);
         setLoading(false);
       }
     };
    fetch();
  },[id, transaction]);

  const handleRefresh = () => {
    fetchTransaction();
    window.location.reload();
  };

  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: "20px" }}>
          Loading...
        </Typography>
      </div>
    );
  }

  if (!data) {
    return (
      <Typography variant="h6" color="error">
        Transaction not found.
      </Typography>
    );
  }

  return (
    <div className="pending-container">
      <Card className="pending-card">
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Pending Transaction
          </Typography>
          <Typography variant="body1">
            <strong>Transaction ID:</strong> {data._id}
          </Typography>
          <Typography variant="body1">
            <strong>Exam:</strong> {data.exam}
          </Typography>
          <Typography variant="body1">
            <strong>Email:</strong> {data.email}
          </Typography>
          <Typography variant="body1">
            <strong>Phone:</strong> {data.phone}
          </Typography>
          <Typography variant="body1">
            <strong>Price:</strong> NGN {data.price}
          </Typography>
          <Typography variant="body1">
            <strong>Quantity:</strong> {data.quantity}
          </Typography>
          <Typography variant="body1">
            <strong>Total Amount:</strong> NGN {data.totalAmount}
          </Typography>
          <Typography variant="body1">
            <strong>Payment Method:</strong> {data.paymentMethod}
          </Typography>
          <Typography variant="body1">
            <strong>Date:</strong> {new Date(data.date).toLocaleString()}
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginTop: "20px" }}
          >
            If payment has been made, please refresh the page after 3-5 minutes.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={handleRefresh}
            style={{ marginTop: "20px" }}
          >
            Refresh
          </Button>
        </CardContent>
      </Card>
    </div>
  );
};

export default Pending;
