import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Pagination,
} from "@mui/material";
import "./transactionHistory.css";

const TransactionHistory = ({ email }) => {
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 10;

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}soldcards/email`,
          {
            email: email
            // params: { email, source: "wallet" },
          }
        );
        const sortedTransactions = response.data.sort(
          (a, b) => new Date(b.Date) - new Date(a.Date)
        );
        setTransactions(sortedTransactions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [email]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const cardTypeCounts = {
    waecresultcheckers: 0,
    necotokens: 0,
    waecgce: 0,
    nabtebresultcheckers: 0,
    nabtebgce: 0,
  };
  let totalCards = 0;
  let totalPrice = 0;

  transactions.forEach((transaction) => {
    if (cardTypeCounts.hasOwnProperty(transaction.cardType)) {
      cardTypeCounts[transaction.cardType]++;
      totalCards++;
      totalPrice += transaction.price;
    }
  });

  if (loading) {
    return (
      <div className="loading-container">
        <CircularProgress />
        <span>Loading....</span>
      </div>
    );
  }

  const displayedTransactions = transactions.slice(
    (page - 1) * rowsPerPage,
    page * rowsPerPage
  );

  return (
    <div className="transaction-history">
      <h2 className="transaction-title">Transactions</h2>
      <div className="card-type-counters">
        {Object.keys(cardTypeCounts).map((key) => (
          <div className="card-type-counter" key={key}>
            <div className="card-type">{key}</div>
            <div className="counter">{cardTypeCounts[key]}</div>
          </div>
        ))}
      </div>
      <div className="total-cards">
        Total Cards Bought: <span>{totalCards}</span>
      </div>
      <div className="total-price">
        Total Amount Spent: <span>NGN {totalPrice}</span>
      </div>
      <TableContainer component={Paper} className="table-container">
        <Table className="transaction-table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Serial Number</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Card Type</TableCell>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedTransactions.map((transaction, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1 + (page - 1) * rowsPerPage}</TableCell>
                <TableCell>{transaction.serial}</TableCell>
                <TableCell>{transaction.price}</TableCell>
                <TableCell>{transaction.cardType}</TableCell>
                <TableCell>{transaction.invoice}</TableCell>
                <TableCell>
                  {new Date(transaction.Date).toLocaleString()}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        count={Math.ceil(transactions.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
        className="pagination"
      />
    </div>
  );
};

export default TransactionHistory;
