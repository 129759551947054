import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";

const StyledBackdrop = styled(Backdrop)({
  zIndex: 1300, // equivalent to theme.zIndex.drawer + 1
  color: "#fff",
});

const Loading = ({ open }) => {
  return (
    <StyledBackdrop open={open}>
      <CircularProgress color="inherit" />
    </StyledBackdrop>
  );
};

export default Loading;
