import React from "react";
import {
  Container,
  CssBaseline,
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Link,
  Paper,
  Button,
} from "@mui/material";

const WaecResultChecker = () => {
  return (
    <Container component="main" maxWidth="md" sx={{ marginBottom: 4 }}>
      <CssBaseline />
      <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
        <Box textAlign="center" sx={{ marginBottom: 2 }}>
          <Button
            variant="contained"
            color="primary"
            href="/product/waecresultcheckers"
          >
            BUY WAEC RESULT CHECKER
          </Button>
        </Box>
        <Box className="header" sx={{ marginBottom: 2 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            How to Check Your WAEC Results Online: A Comprehensive Guide
          </Typography>
          <Typography variant="body1">
            Learn the step-by-step process to check your WAEC results online or
            via SMS. Find out how to purchase the result checker PIN and
            troubleshoot common issues.
          </Typography>
        </Box>
        <Box className="toc" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Table of Contents
          </Typography>
          <List>
            <ListItem component="a" href="#what-is-waec">
              <ListItemText primary="What is the WAEC Result Checker?" />
            </ListItem>
            <ListItem component="a" href="#check-online">
              <ListItemText primary="Step-by-Step Guide to Checking WAEC Results Online" />
            </ListItem>
            <ListItem component="a" href="#check-sms">
              <ListItemText primary="How to Check WAEC Results via SMS" />
            </ListItem>
            <ListItem component="a" href="#purchase-pin">
              <ListItemText primary="Purchasing the WAEC Result Checker PIN" />
            </ListItem>
            <ListItem component="a" href="#troubleshooting">
              <ListItemText primary="Common Issues and Troubleshooting" />
            </ListItem>
            <ListItem component="a" href="#faqs">
              <ListItemText primary="FAQs about WAEC Results" />
            </ListItem>
            <ListItem component="a" href="#conclusion">
              <ListItemText primary="Conclusion" />
            </ListItem>
          </List>
        </Box>
        <Box id="what-is-waec" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            What is the WAEC Result Checker?
          </Typography>
          <Typography variant="body1">
            The WAEC result checker allows students and educational institutions
            to verify exam results promptly and accurately.
          </Typography>
        </Box>
        <Box id="check-online" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Step-by-Step Guide to Checking WAEC Results Online
          </Typography>
          <Typography variant="body1">
            <ol>
              <li>
                Visit the{" "}
                <Link href="http://www.waecdirect.org">WAEC Portal</Link>.
              </li>
              <li>
                Enter Your Examination Number (e.g., 7-digit center number
                followed by 3-digit candidate number).
              </li>
              <li>Select Examination Year (e.g., 2023).</li>
              <li>Select Examination Type (e.g., SCHOOL CANDIDATE RESULT).</li>
              <li>Enter e-PIN Details.</li>
              <li>Click Submit and view your results.</li>
            </ol>
          </Typography>
        </Box>
        <Box id="check-sms" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            How to Check WAEC Results via SMS
          </Typography>
          <Typography variant="body1">
            Format your SMS as WAEC*ExamNo*PIN*ExamYear and send to 32327. For
            example, WAEC4250101001123456789012*2023. Supported networks include
            MTN, Airtel, and Glo.
          </Typography>
        </Box>
        <Box id="purchase-pin" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Purchasing the WAEC Result Checker PIN
          </Typography>
          <Typography variant="body1">
            You can purchase the PIN online via the WAEC Request Management
            System or at authorized centers. The cost is approximately
            N3,654.82.
          </Typography>
        </Box>
        <Box id="troubleshooting" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Common Issues and Troubleshooting
          </Typography>
          <Typography variant="body1">
            <ul>
              <li>
                Incorrect Details: Ensure your examination number and PIN are
                correct.
              </li>
              <li>
                Technical Issues: Retry later or use a different device/browser.
              </li>
              <li>
                Contact Support: Email waecsupport@fleettechltd.com with your
                details and the error message.
              </li>
            </ul>
          </Typography>
        </Box>
        <Box id="faqs" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            FAQs about WAEC Results
          </Typography>
          <Typography variant="body1">
            <ul>
              <li>
                How many times can I use a result checker PIN? - Typically,
                once.
              </li>
              <li>
                What do I do if my result is withheld? - Contact WAEC support
                for assistance.
              </li>
              <li>
                Can I check results from previous years? - Yes, using the same
                steps.
              </li>
            </ul>
          </Typography>
        </Box>
        <Box id="conclusion" sx={{ marginBottom: 2 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Conclusion
          </Typography>
          <Typography variant="body1">
            Checking your WAEC results is easy and convenient with the WAEC
            result checker. Ensure you keep your PIN and examination details
            secure. Share this guide with others who might find it helpful.
          </Typography>
        </Box>
        <Box textAlign="center" sx={{ marginTop: 4 }}>
          <Button
            variant="contained"
            color="primary"
            href="/product/waecresultchecker"
          >
            Buy Now
          </Button>
        </Box>
      </Paper>
      <footer>
        <Typography
          variant="body2"
          color="textSecondary"
          align="center"
          sx={{ marginTop: 2 }}
        >
          © 2023 WAEC Result Checker Guide
        </Typography>
      </footer>
    </Container>
  );
};

export default WaecResultChecker;
