import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./SoldCardsTable.css";

const SoldCardsTable = () => {
  const [soldCards, setSoldCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const exam = query.get("exam");
  const time = query.get("time");

  useEffect(() => {
    const fetchSoldCards = async () => {
      setLoading(true);
      try {
        const response = await axios.post(
          process.env.REACT_APP_BASE_URL + "soldcards/by-time",
          { exam, time, page: currentPage, limit: 20 }
        );
        setSoldCards(response.data.soldCards);
        setTotalPages(response.data.totalPages);
      } catch (error) {
        console.error("Error fetching sold cards:", error);
      }
      setLoading(false);
    };

    if (exam && time) {
      fetchSoldCards();
    }
  }, [exam, time, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return <div className="loader">Loading...</div>;
  }

  return (
          <center>
    <div className="table-container">
      <table className="sold-cards-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Email</th>
            <th>Card Type</th>
            <th>Serial</th>
            <th>Pin</th>
            <th>Phone</th>
            <th>Date</th>
            <th>Price</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {soldCards.map((card, index) => (
            <tr key={card._id}>
              <td>{soldCards.length - index + (currentPage - 1) * 20}</td>
              <td>{card.email}</td>
              <td>{card.cardType}</td>
              <td>{card.serial}</td>
              <td>{card.pin}</td>
              <td>{card.phone}</td>
              <td>{new Date(card.Date).toLocaleString()}</td>
              <td>{card.price}</td>
              <td>{card.source}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={index + 1 === currentPage ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
    </center>
  );
};

export default SoldCardsTable;
