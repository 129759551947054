import React, { useState } from "react";
import {
  TextField,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import "./FundWithCard.css";
import FlutterwaveButton from "./FlutterwaveButton";
import { CreditCard } from "@mui/icons-material";

const FundWithCard = ({ profile, setLoading }) => {
    console.log("from fund with card", profile)
  const [amount, setAmount] = useState("");

  const handleAmountChange = (e) => setAmount(e.target.value);

  return (
    // <div className="fund-with-card-container">
    <div>
      <center>
        <Card className="fund-card">
          <CardContent>
            <Typography variant="h5" component="div" gutterBottom>
              Fund With Card <CreditCard />
            </Typography>

            <TextField
              label="Amount"
              variant="outlined"
              value={amount}
              onChange={handleAmountChange}
              fullWidth
              className="amount-input"
              type='number'
              placeholder="Type in amount"
              style={{
                marginBottom:20
              }}
            />
            <FlutterwaveButton
              data={{
                email: profile.email,
                phone: profile.phone,
                exam: "funding",
                price: amount,
                quantity: 1,
                amount: amount,
              }}
              setLoading={setLoading}
            />
          </CardContent>
        </Card>
      </center>
    </div>
  );
};

export default FundWithCard;
