// src/analytics.js
import ReactGA from "react-ga";

const trackingId = "G-HGW5PJFF0F"; // Replace with your actual tracking ID
ReactGA.initialize(trackingId);

export const trackPage = (page) => {
  ReactGA.set({ page });
  ReactGA.pageview(page);
};
