import React, { useEffect, useState } from "react";
import axios from "axios";
import { TextField, CircularProgress } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     return (window.location.href = "/account");
  //   }
  // }, []);

  const handleEmailChange = (e) => {
    setEmail(e.target.value.trim());
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        process.env.REACT_APP_BASE_URL+"users/login",
        { email, password }
      );

      if (response.status === 200) {
        setLoading(false);
        toast.success("Login successful!");
        localStorage.setItem("token", response.data.token); // Save the token to localStorage
        return (window.location.href = "/account");
      } else {
        setLoading(false);
        toast.error("Invalid email or password");
      }
      if(response.status === 401){
        setLoading(false);
        toast.error("Invalid email or password");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <div className="login-container">
      <ToastContainer />

      <center>
        <br />
        <br />
        <br />
        <span
          style={{
            marginBottom: 10,
          }}
        >
          LOGIN
        </span>
        <br />
        <br />
        <form onSubmit={handleSubmit} className="login-form">
          <span className="my-1">
            <TextField
              id="outlined-basic-email"
              label="Email Address"
              variant="outlined"
              placeholder="Type in your email address"
              value={email}
              onChange={handleEmailChange}
              fullWidth
            />
          </span>
          <span className="my-1">
            <TextField
              id="outlined-basic-password"
              label="Password"
              type="password"
              variant="outlined"
              placeholder="Type in your password"
              value={password}
              onChange={handlePasswordChange}
              fullWidth
            />
          </span>
          <span className="my-1">
            <button type="submit" className="login-button" disabled={loading}>
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Login"
              )}
            </button>
          </span>
        </form>
        <br />
        <span className="forgot-password-link">
          <a href="/forgot-password">Forgotten Password?</a>
        </span>
      </center>
    </div>
  );
};

export default Login;
