import React, { useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import VantaBackground from "../../components/VantaBackground/VantaBackground";
import ProductList from "../../components/ProductList/ProductList";
import "./Home.css";
import { Login, Person } from "@mui/icons-material";
import AboutUs from "./components/Sections/AboutUs";
import Testimonials from "./components/Sections/Testimonials/Testimonials";
import Partners from "./components/Sections/Partners/Partners";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const aboutRef = useRef(null);
  const testimonialsRef = useRef(null);
  const partnersRef = useRef(null);

  // useEffect(() => {
  //   const isLoggedIn = checkIfUserIsLoggedIn();
  //   if (isLoggedIn) {
  //     navigate("/account");
  //   }
  // }, [navigate]);

  useEffect(() => {
    if (location.hash === "#about") {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (location.hash === "#testimonials") {
      testimonialsRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (location.hash === "#partners") {
      partnersRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  // const checkIfUserIsLoggedIn = () => {
  //   const token = localStorage.getItem("token");
  //   return !!token;
  // };

  return (
    <div className="home-container">
      <VantaBackground />
      <div className="home-content">
        <h1 className="home-title">Welcome to ResultCheckers</h1>
        <div className="intro-text">
          <p>
            Your one-stop solution for accessing result tokens and pins
            efficiently and securely.
          </p>
        </div>
        <ProductList />
        <div className="auth-buttons">
          <button
            className="auth-button login-button"
            onClick={() => navigate("/login")}
          >
            <Login style={{ marginRight: "8px" }} />
            LOGIN
          </button>
          <button
            className="auth-button login-button"
            onClick={() => navigate("/register")}
          >
            <Person style={{ marginRight: "8px" }} />
            SIGN UP
          </button>
        </div>
      </div>
      <div ref={aboutRef}>
        <AboutUs />
      </div>
      <div ref={testimonialsRef}>
        <Testimonials />
      </div>
      <div ref={partnersRef}>
        <Partners />
      </div>
    </div>
  );
};

export default Home;
