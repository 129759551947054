import React from 'react';

const ConfirmRow = ({ name, value }) => {
    return (
        <div className="confirm-row border-b-2 border-gray-300 border-dashed border-black border-opacity-100 flex justify-between p-2">
            <span className="key self-start ml-10">{name}</span>
            <span className="value self-end mr-10">{value}</span>
        </div>
    );
};

export default ConfirmRow;