import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import "./SoldCardsByEmail.css";

const SoldCardsByEmail = () => {
  const [soldCards, setSoldCards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const location = useLocation();
  const itemsPerPage = 20;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");

    if (email) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}soldcards/email`, { email })
        .then((response) => {
          setSoldCards(response.data);
          setTotalPages(Math.ceil(response.data.length / itemsPerPage));
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false);
        });
    }
  }, [location.search]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (loading) {
    return <div className="loader">Loading...</div>;
  }

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentSoldCards = soldCards.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  return (
    <div className="sold-cards-container">
      <h2>Sold Cards</h2>
      <table className="sold-cards-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Card Type</th>
            <th>Serial</th>
            <th>Pin</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Date</th>
            <th>Price</th>
            <th>Invoice</th>
            <th>Source</th>
          </tr>
        </thead>
        <tbody>
          {currentSoldCards.map((card, index) => (
            <tr key={card._id}>
              <td>{startIndex + index + 1}</td>
              <td>{card.cardType}</td>
              <td>{card.serial}</td>
              <td>{card.pin}</td>
              <td>{card.email}</td>
              <td>{card.phone}</td>
              <td>{new Date(card.Date).toLocaleString()}</td>
              <td>{card.price}</td>
              <td>{card.invoice}</td>
              <td>{card.source}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={page === currentPage ? "active" : ""}
          >
            {page}
          </button>
        ))}
      </div>
    </div>
  );
};

export default SoldCardsByEmail;
