import React, { useEffect, useState, useRef } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [token, setToken] = useState(null);
  const navRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    }


    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        const nav = document.querySelector(".navbar-collapse");
        if (nav.classList.contains("show")) {
          nav.classList.remove("show");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!token) {
    return (
      <Navbar
        variant="dark"
        expand="lg"
        style={{ backgroundColor: "black", color: "white" }}
      >
        <Navbar.Brand href="/" style={{ paddingLeft: 50 }}>
          <img src="/img/logo2.png" width={50} alt="resultcheckers" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" ref={navRef}>
          <Nav className="ml-auto">
           { location.pathname.includes('/admin')
              ?
              <>
                <LinkContainer to="/admin/soldcards">
                   <Nav.Link>Sold Cards</Nav.Link>
                </LinkContainer>
              </>
              :
            <>
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <span style={{ margin: "0 10px" }}></span>
              <LinkContainer to="/">
                <Nav.Link>About Us</Nav.Link>
              </LinkContainer>
              <span style={{ margin: "0 10px" }}></span>
              <LinkContainer to="/">
                <Nav.Link>Testimonials</Nav.Link>
              </LinkContainer>
              <span style={{ margin: "0 10px" }}></span>
              <LinkContainer to="/">
                <Nav.Link>Partners</Nav.Link>
              </LinkContainer>
              <span style={{ margin: "0 10px" }}></span>
              <LinkContainer to="/contact">
                <Nav.Link>Contact Us</Nav.Link>
              </LinkContainer>
              <span style={{ margin: "0 10px" }}></span>
              <LinkContainer to="/login">
                <Nav.Link>Login</Nav.Link>
              </LinkContainer>
              <span style={{ margin: "0 10px" }}></span>
              <LinkContainer to="/register">
                <Nav.Link>Register</Nav.Link>
              </LinkContainer>
            </>}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }

  return null;
};

export default Header;
