import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./ResetPassword.css";


const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
   const { token } = useParams();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      return;
    }

    try {
      setLoading(true)
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}reset-password`,
        {
          token,
          newPassword: password,
        }
      );
      setMessage(response.data.message);
      setLoading(false)
      alert("Password Reset was successful")
       navigate("/login");
    } catch (error) {
      setMessage(error.response.data.message);
      setLoading(false)
    }
  };

  return (
    <div className="reset-password-container">
      <form onSubmit={handleResetPassword} className="reset-password-form">
        <h2>Reset Password</h2>
        <input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <button type="submit" disabled={loading}>
          {loading ? "Updating Password..." : "Reset Password"}
        </button>
        {message && <p>{message}</p>}
      </form>
    </div>
  );
};

export default ResetPassword;
