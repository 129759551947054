import React, { useState } from "react";
import {  TextField } from "@mui/material";
import "./ProductDescription.css";
import FlutterwaveButton from "../../../../components/FlutterwaveButton";
import Loading from "../../../../components/Loading";
import MonnifyBankButton from "../../../../components/MonnifyBankButton/index.js";

const ProductDescription = ({ product }) => {
  const price = product.price;
  const [quantity, setQuantity] = useState(1);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  const handleIncrease = () => setQuantity((q) => q + 1);
  const handleDecrease = () => setQuantity((q) => Math.max(1, q - 1));
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneChange = (e) => {
    const onlyNumbers = e.target.value.replace(/[^0-9]/g, "");
    setPhone(onlyNumbers);
  };
 
if(loading){
  return (
    <Loading open={loading}/>
  )
}


  return (
    <div className="product-description">
      <div className="image-container">
        <img src={product.image} alt={product.title} width={300} height={280} />
      </div>
      <div className="description-container">
        <h1 style={{ fontSize: 20, fontWeight: '800' }}>{product.title}</h1>
      { product.available ?  <div className="description-content">
          <p><strong>{product.description}</strong></p>
          <p><strong>Format:</strong> PIN (TOKEN)</p>
          <p><strong>Token Delivery:</strong> INSTANT DISPLAY</p>
          <h2>How to Use:</h2>
          <ol>
            {product.instructions.map((instruction, index) => (
              <li key={index}>{index+1}{`.      `} {instruction}</li>
            ))}
          </ol>
          {product.product==="necotokens"&&<p className="note"><strong>Please Note:</strong> This token can ONLY be used up to 5 times for a particular Registration Number.</p>}
          <div className="quantity-selector">
            <button onClick={handleDecrease} className="quantity-button">-</button>
            <center className="text-xl">{quantity}</center>
            <button onClick={handleIncrease} className="quantity-button">+</button>
            <span className="text-xl">Total Amount NGN{quantity * price}</span>
          </div>
        <div className="payment-form">
            <TextField id="outlined-basic-email" label="Email Address" variant="outlined" placeholder="Type in your email address" value={email} onChange={handleEmailChange} />
            <TextField id="outlined-basic-phone" label="Phone Number" variant="outlined" placeholder="Type in your phone number" value={phone} onChange={handlePhoneChange} />
            <div className="payment-buttons">
             <FlutterwaveButton setLoading={setLoading} data={{
                exam: product.product,
                email,
                phone,
                price: product.price,
                amount: quantity * price,
                product: product.title,
                quantity
              }}/>
              
              <MonnifyBankButton data={{ email, phone, quantity, product, price} }/>
            </div>
          </div>
        </div>:
        <div>
          <span>Currently Out Of Stock</span>
          </div>
        }
      </div>
    </div>
  );
};

export default ProductDescription;
