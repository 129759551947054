import React, { useState } from "react";

const Vpay = () => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
//  const base_url = "https://dropin-sandbox.vpay.africa"
 const base_url = "https://dropin.vpay.africa"
  const loadScript = (src, onload) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = onload;
    script.async = true;
    document.body.appendChild(script);
  };

  const initializeVPay = () => {
    const options = {
      amount: 100,
      currency: "NGN",
    //   domain: "sandbox",
      key: "b21c1c21-706f-4386-8287-020c2a320db3",
      email: "ubongcj@gmail.com",
      transactionref: "z31zs098zas8w3774h44344f8q5ydg0121",
      customer_logo: "https://utilitywallet.ng/img/utilitywallet.png",
      // "https://www.vpay.africa/static/media/vpayLogo.91e11322.svg",
      customer_service_channel: "+2348030007000, support@org.com",
      txn_charge: 0,
      txn_charge_type: "flat",
      onSuccess: function (response) {
        console.log("Hello World!", response.message);
      },
      onExit: function (response) {
        console.log("Hello World!", response.message);
      },
    };

    if (window.VPayDropin) {
      const { open, exit } = window.VPayDropin.create(options);
      open();
    }
  };

  const handleButtonClick = () => {
    if (!isScriptLoaded) {
      loadScript(`${base_url}/dropin/v1/initialise.js`, () => {
        setIsScriptLoaded(true);
        initializeVPay();
      });
    } else {
      initializeVPay();
    }
  };

  return (
    <div>
      <h1>Order Checkout Page</h1>
      {/* Your additional HTML content goes here */}
      <button onClick={handleButtonClick}>Pay Now</button>
    </div>
  );
};

export default Vpay;
